import React from 'react';
import './stylesheets/index.scss';
import freyaMiner from "./images/freya-miner.svg";
import thorMiner from "./images/thor-miner.svg";
import odinMiner from "./images/odin-miner.svg";
import shield from "./images/corner-shield.svg";
import key from "./images/key.svg";
import ktvLogo from "./images/keytovalhalla.svg";
import freySymbol from "./images/freya-symbol.svg";
import thorSymbol from "./images/thor-symbol.svg";
import odenSymbol from "./images/odin-symbol.svg";
import twitter from "./images/logo-twitter.svg";
import discord from "./images/logo-discord.svg";
import './App.css';


import { useEffect } from "react";

function App() {
  useEffect(() => {
      // Add the black-background class to the body when the component mounts
      document.body.classList.add('black-background');
      // Remove the black-background class from the body when the component unmounts
      return () => {
          document.body.classList.remove('black-background');
      };
  }, []);

  return (
        <div className="ktv-background">
            <div className="position-absolute top-0 start-0 mt-3 mx-3">
                <img className="corner-piece" src={shield} alt="Shield" />
            </div>
            <div className="position-absolute top-0 end-0 mt-3 mx-3">
                    <img className="corner-piece right-corner" src={shield} alt="Shield" />
                </div>
            <div className="container miner-info">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="mt-5">
                            <img src={ktvLogo} alt="" />
                            <h2 className="headline fw-light mt-4 text-white">Unlock the Gates of Valhalla</h2>
                        </div>
                        <div className="mt-4 key-container">
                            <a href="https://l1on.xyz/project/KeyToValhalla" target={"_blank"} className="btn btn-dark centered-btn">Buy Ordinal</a>
                            <img src={key} className="key" alt="Rotating Key Image" />
                        </div>
                        <div className="mt-4">
                            <h5 className="text-center mx-auto about-text fw-light text-white">Embark on a quest to discover the elusive keys leading to Valhalla’s revered halls. Immerse yourself in the rich tapestry of Norse mythology, uncovering the route to eternal renown within your ancestral home. Should the gods favor you, they will bestow the Magic Runes that illuminate the path to everlasting glory in Valhalla.</h5>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="row justify-content-center mt-5">
                        <div className="col text-center text-gold">
                            <h1 className="keys-title">The Keys (miners)</h1>
                            <h4 className="section-heading text-white">ᚢᚨᛚᚺᚨᛚᛚᚨ ᚱᚢᚾᛖᛋ</h4>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-5">
                        <div className="col-md-4">
                            <div className="miner">
                                <img src={odinMiner} className="mb-3" alt="Odin" />
                                <div className="text-center px-3">
                                    <div className="d-flex justify-content-center align-item-center ">
                                        <img className="mx-2" src={odenSymbol} alt="Rune Symbol" />
                                        <h2 className="oden-color m-0">Odin</h2>
                                    </div>
                                    <h4 className="oden-color mt-3">Supply: 4000</h4>
                                    <h6 className="oden-color">Common</h6>
                                    <ul className="mt-3 px-0 text-white">
                                        <li className="my-1 fs-6">Start Block: 839,330</li>
                                        <li className="my-2 fs-6">Points per Block: 62.5</li>
                                        <li className="my-1 fs-6">Bonus at Halving: 420,000</li>
                                        <li className="my-2 fs-6">End Block: 843,530</li>
                                        <li className="fs-6">Diamond Hand: Valhalla</li>
                                    </ul>
                                    <div className="divider">
                                        <span className="divider-line oden"></span>
                                        <span className="divider-symbol">🔲</span>
                                        <span className="divider-line oden"></span>
                                    </div>
                                    <p className='text-white'>
                                        With 4000 Odin assets in total, the cumulative mining output for all Odin assets will reach 1,680,000,000 units.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="miner">
                                <img src={thorMiner} className="mb-3" alt="Odin" />
                                <div className="text-center px-3">
                                <div className="d-flex justify-content-center align-item-center ">
                                        <img className="mx-2" src={thorSymbol} alt="Rune Symbol" />
                                        <h2 className="thor-color m-0">Thor</h2>
                                    </div>
                                    <h4 className="thor-color mt-3">Supply: 2500</h4>
                                    <h6 className="thor-color">Legendary</h6>
                                    <ul className="mt-3 px-0">
                                        <li className="my-1 fs-6">Start Block: 839,330</li>
                                        <li className="my-2 fs-6">Points per Block: 160</li>
                                        <li className="my-1 fs-6">Bonus at Halving: 420,000</li>
                                        <li className="my-2 fs-6">End Block: 843,530</li>
                                        <li className="fs-6">Diamond Hand: Valhalla</li>
                                    </ul>
                                    <div className="divider">
                                        <span className="divider-line thor"></span>
                                        <span className="divider-symbol">🔲</span>
                                        <span className="divider-line thor"></span>
                                    </div>
                                    <p>
                                        With 2500 Thor assets in total, the cumulative mining output for all Thor assets will reach 1,050,000,000 units.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="miner">
                                <img src={freyaMiner} className="mb-3" alt="Odin" />
                                <div className="text-center px-3">
                                    <div className="d-flex justify-content-center align-item-center ">
                                        <img className="mx-2" src={freySymbol} alt="Rune Symbol" />
                                        <h2 className="freya-color m-0">Freya</h2>
                                    </div>
                                    <h4 className="freya-color mt-3">Supply: 3500</h4>
                                    <h6 className="freya-color">Rare</h6>
                                    <ul className="mt-3 px-0">
                                        <li className="my-1 fs-6">Start Block: 839,330</li>
                                        <li className="my-2 fs-6">Points per Block: 100</li>
                                        <li className="my-1 fs-6">Bonus at Halving: 420,000</li>
                                        <li className="my-2 fs-6">End Block: 843,530</li>
                                        <li className="fs-6">Diamond Hand: Valhalla</li>
                                    </ul>
                                    <div className="divider">
                                        <span className="divider-line freya"></span>
                                        <span className="divider-symbol">🔲</span>
                                        <span className="divider-line freya"></span>
                                    </div>
                                    <p>
                                        With 3500 Freya assets in total, the cumulative mining output for all Freya assets will reach 1,470,000,000 units.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="ending-background">
                <div className="container">
                    <h2 class="text-center mt-5 text-white">The Key•To•Valhalla Rune</h2>
                    <h5 className='text-white text-center mx-auto mb-4'>At the core of the Key•To•Valhalla meme token is a foundational element driving our community’s quest for eternal glory.</h5>
                    <div class="row">
                        <p>Let’s delve into the breakdown of tokenomics:</p>
                        <div class="col d-flex ">
                            

                            <ul>
                                <li>Total Supply: 10 billion Rune tokens</li>
                                <li>Key To Valhalla Ordinal Holders (Miners): 4.2 billion tokens (42%)</li>
                                <li>Open Mint: 4.2 billion tokens (42%)</li>
                                <li>Other (Liquidity Provision, Promotion): 1 billion tokens (10%)</li>
                                <li>Team Allocation: 500 million tokens (5%)</li>
                                <li>Giveaways, Airdrops, Rewards, etc.: 100 million Tokens (1%)</li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <p>This segment of the supply is dedicated to those holding the Key To Valhalla Ordinal, affectionately known as miners. By inscribing this “free-to-claim” asset, users are entitled to claim a portion of the Key To Valhalla rune tokens based on the rarity of the asset. These holders make up our community of early adopters, reaping the rewards for their efforts of being early supporters and will enjoy V.I.P. status using the MagicRunes.io platform.</p>
                            <p>The open mint represents tokens available for minting by anyone to gain a better distribution and grow the community. This decentralized approach ensures equitable distribution and broad engagement in the Rune “meme” token ecosystem. Any unmined tokens will face burning, curbing supply, and enhancing scarcity over time.</p>
                            <p>A significant share of the supply is earmarked for diverse ecosystem initiatives, encompassing liquidity provision, marketing endeavors, promotional campaigns, community giveaways, airdrops, and rewards programs. These resources bolster KEY•TO•VALHALLA community growth, fostering engagement and adoption throughout the Bitcoin realm.</p>
                            <p>A small yet vital allocation is reserved for the team driving the KEY•TO•VALHALLA meme token project. This allocation fuels development, marketing, and operational endeavors, securing the project’s longevity and sustainability.</p>
                            </div>
                    </div>
                </div>
            </div>

            {/* <div className="ending-background">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">
                                <h5 className="end-copy text-white">Guided by the wisdom of Thor, Freya, and Odin, and fueled by the determination of 10,000 rune miners, we stand on the threshold of Valhalla's hallowed halls. The RUNEs we've forged will endure for eternity. With the Key to Valhalla in hand, we unlock the gates to eternal glory, where the honored warriors of old await our arrival. As the winds of destiny whisper through the realms of Asgard, our journey has just begun. Until we meet again in the realms beyond, may the spirit of Valhalla dwell within us all. Skål!</h5>
                            </div>
                            <div className="text-center mt-4">
                                <a href="https://l1on.xyz/project/KeyToValhalla" target={"_blank"} className="btn btn-black">Now Minting</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid ktv-footer bg-black d-flex justify-content-center align-items-center">
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-center gap-4">
                            <p className="text-center text-light mx-2">Crafted with BTC energy by <a href={"https://l1on.xyz"} target={"_blank"}>L1ON.xyz</a></p>
                            <p className="text-center text-light mx-2">Discover the Runes Platform <a href={"https://magicrunes.io/"} target={"_blank"}>MagicRunes</a></p>
                        </div>
                        <div className="d-flex justify-content-center gap-4 mt-5">
                            <a
                                href="https://twitter.com/L1ON_BTC"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={twitter}
                                    alt="twitter"
                                />
                            </a>

                            <a
                                href="https://twitter.com/L1ON_BTC"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={discord}
                                    alt="discord"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
